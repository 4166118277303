import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
    Accueil: "/",
    "Consultation Analytics": "/fr/consultation-analytique",
    "Couche de Données": "/fr/couche-de-données"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

  const DataLayer = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Couche de Données expliquée avec Tutoriel [2020]"
          description="Une couche de données est le concept fondamental pour toute configuration professionnelle d'analyse web. Mais qu'est-ce que c'est réellement et comment l'implémentons-nous ?"
          lang="fr"
          image="data-layer-code-hero-image3"
          alternateLangs={alternateLangs}
          canonical="/fr/couche-de-données"
          datePublished="2024-06-18T04:32:43.188Z"
          dateModified="2024-06-18T15:21:48.881Z"
        />
        <MainContent article>
          <ImgScreenshot
            src="data-layer/data-layer-code-hero-image3.png"
            alt="Visualisation de la Couche de Données de Google Tag Manager dans la console du navigateur"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Couche de Données</H>
<p>
  En relation avec la gestion des balises et l'analyse web, vous avez peut-être entendu le terme <b>couche de données</b>. C'est l'élément fondamental dans une configuration d'analyse web ambitieuse car tous les points de données et règles de suivi en dépendent.
</p>
<p>
  C'est pourquoi dans le domaine de l'analytique numérique, il est traité comme une exigence non négociable pour toute configuration d'analyse. Cependant, il existe également des scénarios où ce n'est pas nécessaire.
</p>
<p>
  Par conséquent, je veux expliquer <Link to="/fr/couche-de-données">ce qu'est une couche de données</Link>, ses <Link to="/fr/couche-de-données">avantages</Link>, et les différences entre la <Link to="/fr/couche-de-données">couche de données pour Google Tag Manager</Link> et <Link to="/fr/couche-de-données">Adobe Launch</Link>.
</p>
<p>
  Ensuite, nous examinerons l'implémentation pour les systèmes de gestion des balises (TMS) les plus populaires. Je vais expliquer la phase de <Link to="/fr/couche-de-données">conception</Link>, suivie par l'<Link to="/fr/couche-de-données">implémentation</Link> et le <Link to="/fr/couche-de-données">débogage</Link>.
</p>
<H as="h2">Qu'est-ce qu'une couche de données ?</H>
<p>Une couche de données est une <b>structure de données</b> qui fournit des informations pertinentes sous forme de paires clé-valeur pour une utilisation avec, par exemple, les <Link to="/fr/gestion-des-tags">systèmes de gestion des balises</Link>.</p>
<p>Une couche de données est disponible dans la portée globale de JavaScript du site web sous forme de <b>tableau</b> ou <b>objet</b> et contient des données structurées pour être utilisées par d'autres programmes.</p>
<p>Le bénéfice d'une couche de données réside dans un accès programmatique simple aux données pertinentes pendant une visite sur le site web.</p>

<p>
  Elle permet l'accès aux données en un point central et constitue la base de la logique d'analyse des données dans un <Link to="/fr/gestion-des-tags">système de gestion des balises</Link>.
</p>
<SnippetJS caption="Extrait de code pour créer une couche de données pour Google Tag Manager. Il crée un objet JavaScript dans un tableau (!) contenant toutes les dimensions pertinentes pour une analyse des données et une création de règles ultérieures dans le système de gestion des balises.">
  {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page",
  "pageName": "sneaker overview",
  "language": "en-US",
}];`}
</SnippetJS>
<p>
  Comme elle est utilisée pour stocker des données provenant de multiples sources, elle simplifie la surveillance des valeurs de données actuelles, car il suffit d'observer un seul endroit ("point de vérité unique").
</p>
<p>
  Une couche de données est <b>reconstruite à chaque chargement de page</b> avec des points de données de la page web actuelle et éventuellement d'autres données pertinentes sur le visiteur et sa visite.
</p>
<p>
  <b>Remarque :</b> Les applications à page unique (SPA) ne rechargent pas la page entre les navigations. C'est pourquoi la configuration de la couche de données pour une application à page unique est différente de celle des pages web typiques avec rechargement de page.
</p>
<p>
  Les données détenues représentent des caractéristiques ou des fonctionnalités d'une sous-page et sont conservées sous forme de paires clé-valeur. Les clés contiennent des noms descriptifs des fonctionnalités associés à une valeur actuelle, qui change généralement au cours du parcours utilisateur.
</p>
<SnippetJS caption="Les caractéristiques pertinentes d'une sous-page, telles que sa catégorie, son nom et sa langue, sont stockées dans des variables et peuvent ensuite être utilisées pour l'analyse ou la création de règles dans le TMS.">
  {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "category page", //category
  "pageName": "sneaker overview", //name
  "language": "en-US", //language
}];`}
</SnippetJS>
<p>
  L'objectif global est de rendre ces points de données disponibles dans le système de gestion des balises, afin qu'ils puissent être envoyés avec les données partagées avec, par exemple, Google Analytics ou Facebook Ads pour mieux décrire les interactions sur le site web.
</p>
<p>
  Pour permettre cette intégration, le TMS contient des références aux paires clé-valeur et peut, par exemple, exécuter des règles lorsque leur valeur change.
</p>
<p>
  <b>Exemple :</b> Un visiteur zoome sur une image de produit et déclenche ainsi un événement "zoom produit". Sans données supplémentaires envoyées avec l'événement, ce n'est pas très instructif. C'est pourquoi nous envoyons également des données sur, par exemple, le nom du produit, sa catégorie et son prix, afin que nous puissions analyser l'événement dans un contexte significatif.
</p>
<p>
  Les données supplémentaires permettraient de vérifier si ces zooms de produit ne se produisent que dans certaines catégories de produits. Si c'est le cas, il peut être avantageux d'ajouter plus d'images à d'autres produits de la même catégorie, car les visiteurs semblent très intéressés par les détails des images de ces produits.
</p>
<p>
  En fin de compte, nous structurons toutes les données pertinentes en clés et valeurs descriptives et les rendons disponibles en un endroit central où elles peuvent être facilement récupérées.
</p>
<p>
  Ces points de données sont généralement des caractéristiques importantes du contenu de la page ou une classification que nous avons établie pour segmenter les visiteurs en fonction de leur comportement.
</p>
<p>
  Pour mieux comprendre ce qu'est une couche de données, comme visualisation simplifiée, vous pouvez imaginer une feuille Excel. La feuille contient des caractéristiques importantes sur une page web dans son en-tête (chemin, langue, catégorie, statut de connexion) avec une valeur actuelle pour chaque élément.
</p>
<ImgContainerFixed width="449px">
  <ImgScreenshot
    src="data-layer/data-layer-structure-excel-example.png"
    alt="Exemple simplifié de couche de données dans Excel"
    className="article-img"
    caption="Vous pouvez imaginer une couche de données comme un simple tableau avec les titres des colonnes "
  />
</ImgContainerFixed>
<H as="h2">Quels sont les avantages d'utiliser une couche de données ?</H>
<p>
  Lorsqu'un visiteur navigue sur un site web, de nombreuses interactions utilisateur ont lieu : clics sur des boutons, formulaires remplis ou vidéos regardées.
</p>
<p>
  Si ces interactions nous permettent de tirer des conclusions sur l'engagement des utilisateurs, elles sont envoyées par exemple à Google Analytics avec d'autres données descriptives sur le visiteur, la session, l'événement lui-même ou l'élément HTML avec lequel ils ont interagi.
</p>
<p>
  <u>Et c'est le point crucial</u> : Les données supplémentaires pour décrire ces interactions proviennent de différentes sources de données, par exemple du <b>frontend, de la base de données</b> ou d'une <b>API</b> externe.
</p>
<p>
  Pour comprendre les avantages d'une couche de données, nous devons d'abord comprendre les défis qui se posent lorsque nous avons besoin de données provenant de multiples sources.
</p>
<p>Voyons un exemple où nous collectons des données de ces sources et réfléchissons-y :</p>
<p>
  <b>Exemple :</b> Le visiteur achète un produit sur un site web. Les dimensions suivantes pourraient être intéressantes :
</p>
<ul
// TODO: afficher la liste en 2 colonnes, problème : semble bon sur ordinateur mais pas sur mobile
// style={{ columns: "2", "-webkit-columns": "2", "-moz-columns": "2" }}
>
  <li>nom du produit</li>
  <li>prix du produit</li>
  <li>taille du produit</li>
  <li>catégorie de produit</li>
  <li>couleur du produit</li>
  <li>valeur du panier</li>
  <li>marque</li>
  <li>premier achat</li>
  <li>segment de clientèle</li>
  <li>remise client</li>
  <li>genre</li>
  <li>pays</li>
</ul>
<p>
  Après l'achat, les visiteurs arrivent sur une page de remerciement listant tous les détails de l'achat et de l'adresse de livraison.
</p>
<p>
  <b>Frontend</b> : Pour envoyer les données du produit, la valeur du panier et la marque avec l'événement, nous pouvons potentiellement les extraire de la page de remerciement.
</p>
<p>
  Le principal défi de l'extraction de données d'une page web est que les données doivent être disponibles sur chaque page où l'interaction a lieu. Ce n'est guère le cas en réalité.
</p>
<p>
  Il est conseillé de mesurer autant de dimensions similaires à travers toutes les interactions d'un site web pour rendre les interactions comparables lors de l'analyse des données. Ainsi, si nous suivons cette approche, il est probable que d'autres pages ne listent pas les <i>données du produit, la valeur du panier et le nom de la marque</i> à envoyer avec d'autres événements.
</p>
<p>
  Donc, si les données nécessaires ne sont pas disponibles sur d'autres pages, nous voulons éviter d'ajouter toutes ces données au contenu juste pour l'analyse. <b>C'est pourquoi nous utilisons une couche de données</b>. Elle rend les données disponibles pour que nous puissions les collecter, qu'elles soient visibles sur la page ou non. C'est littéralement une couche de données qui repose sur n'importe quelle sous-page donnée, fournissant les données dont nous avons besoin.
</p>
<p>
  Un autre défi de l'extraction de données du frontend est qu'elle finit par se briser. Lorsque les pages sont modifiées et que ces changements affectent la structure HTML des éléments extraits, alors la collecte de données échouera. Surtout dans les grandes entreprises, il y a des changements fréquents sur les pages et plusieurs équipes y travaillent sans savoir si certains éléments HTML sont nécessaires pour la collecte de données. Ainsi, toute extraction de données à partir du frontend se brisera à un moment donné sur des sites fréquemment mis à jour.
</p>
<p>
  Une couche de données exploite cette approche et permet de récupérer les données utilisateur de manière sécurisée et pourtant simple.
</p>
<p>
  <b>Base de données</b> : Collecter les données client (segment de clientèle, remise, genre et premier achat) peut devenir compliqué : Les données client doivent soit être envoyées avec la réponse du serveur, soit avec une API distincte.
</p>
<p>
  Mais comme il s'agit de données privées, la collecte doit être authentifiée pour des raisons de protection des données. Cela signifie qu'une requête API ne pourrait pas être gérée dans le navigateur car la clé API serait autrement récupérable par des utilisateurs expérimentés.
</p>
<p>
  Par conséquent, la meilleure solution est d'envoyer les données avec la réponse du serveur en fonction de l'authentification de connexion du site web. <br />
  Lorsque l'utilisateur est connecté, la couche de données est remplie avec les données pertinentes de la base de données. Sans connexion, aucune donnée sensible n'est exposée.
</p>
<p>
  <b>API</b> : Les données géographiques telles que le pays peuvent être récupérées à partir d'une API de service externe.
</p>
<p>
  Cependant, le même défi se pose lors de la récupération de données à partir de la base de données : Toute requête API depuis le frontend ou le système de gestion des balises nécessite une clé API, qui ne devrait pas être gérée dans le navigateur pour des raisons de sécurité.
</p>
<p>
  Un autre inconvénient du travail avec des API pour la collecte de données, en particulier avec des événements, est la durée jusqu'à ce que les données reviennent. Si un utilisateur navigue vers une autre page avant que les données n'arrivent, nous risquons de perdre l'événement.
</p>
<p>Résumons rapidement les avantages :</p>
<H as="h3">Avantages</H>
<ul>
  <li>Les données sont disponibles, qu'elles soient visibles sur la page ou non</li>
  <li>Collecte de données robuste</li>
  <li>Collecte sécurisée de données sensibles</li>
  <li>Réduction de la perte de données pour les requêtes de données asynchrones</li>
</ul>
<H as="h2">Pourquoi vous en avez probablement besoin</H>
<p>
  En créant une couche de données, un objet JavaScript est rendu disponible dans la portée globale du navigateur à chaque chargement de page.
</p>
<p>
  Les données qu'il contient peuvent provenir de votre base de données, du frontend ou des API, de sorte que la collecte de données à partir de ces sources devient fiable, sécurisée et indépendante du HTML sur la page.
</p>
<p>
  Les données de la base de données peuvent être rendues disponibles sur n'importe quelle sous-page du site sans beaucoup de tracas sans être visibles dans le contenu.
</p>
<p>
  Pour les raisons ci-dessus, je conseille généralement aux clients de mettre en œuvre des couches de données s'ils prennent leurs ambitions d'analyse de données au sérieux. Les avantages en termes de qualité des données, de fiabilité et de gain de temps à long terme justifient les efforts d'implémentation plus élevés.
</p>
<p>
  L'objectif ultime de l'analyse web est de prendre des décisions commerciales basées sur les données, donc la qualité des données doit être une priorité.
</p>
<p>
  Maintenant, regardons les différentes options disponibles et quelques exemples d'implémentation avant de plonger dans la phase de conception et d'implémentation.
</p>
<H as="h2">Couche de Données pour Adobe Analytics, DTM, Launch et Tealium</H>
<p>
  Les couches de données peuvent avoir différentes structures. En général, nous distinguons celles qui viennent avec une <b>structure basée sur les objets</b> et une <b>structure basée sur les tableaux</b>.
</p>
<p>
  Selon la{" "}
  <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
    définition de la couche de données
  </a>{" "}
  par le World Wide Web Consortium (W3C), la syntaxe suit celle d'un objet JavaScript. Elle est officieusement raccourcie en <b>CEDDL</b> (Customer Experience Digital Data Layer).
</p>
<p>Vous pouvez également y imbriquer d'autres objets ou tableaux :</p>
<SnippetJS caption="Exemple de couche de données pour Adobe Analytics avec une structure basée sur les objets, suivant la définition W3C.">
{`
window.digitalData = {
  pageName: "aperçu des sneakers",
  destinationPath: "/fr/sneakers",
  breadCrumbs: ["accueil","sneakers"],
  publishDate: "2020-07-01",
  language: "fr-FR"
};`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics, Adobe Launch</b> et Tealium suivent la structure CEDDL. Dans l'exemple ci-dessus, nous stockons les données dans un objet appelé <code>digitalData</code>. Le nom n'est pas standardisé et peut être choisi librement, mais vous devez déclarer le nom dans le système de gestion des balises.
        </p>
        <p>
          Pour modifier les données, il existe plusieurs options (comme pour tout objet JS) cependant, la manière la plus simple est de simplement écraser les valeurs :
        </p>
        <SnippetJS caption="Pour ajouter des données à la couche de données, il suffit de remplacer les données existantes comme avec tout objet JavaScript classique.">
          {`window.digitalData.language = "de-DE";`}
        </SnippetJS>
        <p>
          L'idée centrale de la structure basée sur les objets est qu'ils sont chargés une fois par chargement de page, mais ne sont pas beaucoup modifiés en fonction de l'interaction de l'utilisateur. Les données sont principalement <b>statiques</b>.
        </p>
        <p>
          Le suivi des événements n'est pas déclenché par des événements qui entrent dans l'objet de la couche de données. Les événements sont suivis avec une <b>bibliothèque de suivi distincte</b> pour les envoyer à une plateforme d'analyse comme Adobe Analytics. Lorsque le code de suivi des événements est exécuté, l'objet de la couche de données est envoyé dans son intégralité et peut être utilisé lors de l'analyse des données.
        </p>
        <SnippetJS caption="Le suivi des événements pour Adobe avec une structure de couche de données basée sur les objets est géré via la bibliothèque _satellite.">
          {`
//Événement avec couleur choisie
_satellite.setVar("couleur des sneakers", "noir");
_satellite.track("sélectionner couleur"); 

`}
        </SnippetJS>
        <H as="h2">Utiliser Adobe Launch avec une couche de données basée sur des tableaux</H>
        <p>
          Vous pouvez facilement utiliser Adobe Launch avec une structure basée sur des tableaux aussi. L'extension Adobe Launch <b>Data Layer Manager</b> le rend possible.
        </p>
        <p>Voici quelques liens vers des ressources supplémentaires pour utiliser la version basée sur des tableaux avec Adobe Launch :</p>
        <ul>
          <li>
            Jim Gordon’s{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Démo de l'utilisation du Data Layer Manager avec Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extension avec{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              documentation
            </a>
          </li>
        </ul>
        <H as="h2">Couche de données pour Google Tag Manager, Matomo et Piwik Pro</H>
        <p>
          La <b>couche de données pour Google Tag Manager</b>, Matomo et Piwik Pro est basée sur des tableaux et est officieusement appelée la couche de données pilotée par les événements <b>(EDDL)</b>.
        </p>
        <p>
          Les données sont également gérées dans des objets, mais la structure globale de la couche de données GTM est un <b>tableau avec des objets</b>.
        </p>
        <SnippetJS caption="Extrait de code pour ajouter une couche de données GTM">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "page de catégorie",
  "pageName": "aperçu des sneakers",
  "language": "fr-FR",
}];
`}
</SnippetJS>
<p>
  La logique de suivi avec une structure basée sur des tableaux est différente : Les nouvelles données ou les changements sont poussés dans la couche de données via{" "}
  <code>dataLayer.push()</code>. Ainsi, une <b>insertion dans la couche de données</b> peut déclencher l'exécution de balises dans le système de gestion des balises.
</p>
<p>
  La différence fondamentale avec une structure basée sur des objets est que les changements sont généralement envoyés avec un événement et que les règles sont déclenchées en fonction de ces changements sans bibliothèque supplémentaire, simplement en observant si le tableau de la couche de données change.
  <br />
  Comme aucune autre bibliothèque comme <code>_satellite</code> n'est nécessaire, nous avons une dépendance en moins.
</p>
<p>
  Une autre caractéristique de l'approche basée sur des tableaux est que les données changent assez fréquemment tout au long du parcours utilisateur, car toute interaction utilisateur peut modifier les variables de la couche de données.
</p>{" "}
<p>
  Ainsi, une couche de données basée sur des tableaux est la base du suivi des événements et gère les données de manière plus flexible, tandis qu'une couche basée sur des objets sert plutôt de magasin de données statiques.
</p>
<p>
  Grâce à cette flexibilité, une structure de couche de données basée sur des tableaux est considérée comme plus adaptée aux applications à page unique (SPA). <br />
  Cependant, vous pouvez également suivre les SPA avec une couche de données basée sur des objets, cela nécessitera simplement quelques lignes de code supplémentaires et potentiellement quelques cas particuliers à résoudre.
</p>
<p>
  Si vous êtes au début d'un projet et avez le choix, je préférerais une couche de données basée sur des tableaux.
  <br />
  Changer une configuration existante d'une structure d'objet à un tableau est cependant inutile.
</p>
<H as="h2">Systèmes de gestion de contenu avec couche de données incluse</H>
<p>
  Les utilisateurs de <b>WordPress</b> ont la tâche facile puisqu'ils peuvent utiliser{" "}
  <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
    ce plugin
  </a>{" "}
  pour implémenter Google Tag Manager avec une couche de données préconfigurée.
</p>
<p>Il se remplit automatiquement avec les catégories, les noms d'auteurs, les dates de publication et les termes de recherche.</p>
<p>
  Les points de données peuvent être cochés ou décochés dans les paramètres du plugin. De plus, le plugin propose des événements préconfigurés pour les soumissions de formulaires des plugins de formulaires les plus courants.
</p>
<p>
  Si vous êtes propriétaire d'une boutique en ligne et utilisez <b>WooCommerce</b> pour WordPress, vous pouvez implémenter une{" "}
  <b>couche de données e-commerce classique</b> ainsi qu'une <b>couche de données e-commerce améliorée</b> avec le même plugin, ce qui est assez puissant.
</p>
<p>
  Les utilisateurs de <b>WordPress</b> qui souhaitent utiliser <b>Tealium</b> peuvent utiliser un plugin pour{" "}
  <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
    Tealium
  </a>
  .
</p>
<p>
  <b>Drupal</b> a également un{" "}
  <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
    plugin
  </a>
  .
</p>
<p>
  Les utilisateurs de <b>Wix</b> et <b>Squarespace</b> peuvent implémenter Google Tag Manager via les outils de la plateforme mais doivent implémenter le code de la couche de données manuellement.
</p>
<H as="h2">Implémentation de la couche de données</H>
<p>
  Alors, comment implémenter une couche de données ? - Puisque la planification et l'implémentation nécessitent des connaissances dans les domaines de l'analyse numérique, du développement frontend et du développement backend, l'implémentation est généralement réalisée par une agence web en collaboration avec un{" "}
  <Link to="/fr/consultant-google-analytics">consultant en analytics</Link>.
</p>
<p>
  Le consultant en analytics briefe l'agence web et dirige le projet jusqu'à ce que l'implémentation soit validée avec succès. Ensuite, le système de gestion des balises et les outils d'analytics sont configurés.
</p>
<p>
  Si vous êtes intéressé et connaissez un peu de JavaScript, vous pouvez l'implémenter vous-même avec le <b>guide d'implémentation</b> suivant.
</p>
<p>L'implémentation se déroule en 3 étapes :</p>
<ol>
  <li>
    <Link to="/fr/couche-de-données">Conception de la couche de données</Link>
  </li>

  <li>
    <Link to="/fr/couche-de-données">Implémentation</Link>
  </li>

  <li>
    <Link to="/fr/couche-de-données">Débogage</Link>
  </li>
</ol>
<H as="h3">1. Conception de la couche de données</H>
<p>
  La phase de conception consiste à définir quelles interactions doivent être mesurées ainsi que quelles dimensions.
</p>
<p>
  Toutes les attributs du visiteur, de la session, de la page, du produit ou de l'événement peuvent être d'un intérêt potentiel lors de l'analyse des données et doivent être pris en compte pour l'architecture de la couche de données.
</p>
<p>
  Pour décider quoi inclure, commencez par la fin en tête et demandez-vous quelles questions critiques pour l'entreprise doivent être abordées et concentrez-vous sur les points de données associés.
</p>
<p>
  L'étape suivante consiste à déterminer comment ces points de données doivent être structurés et quelles dimensions sont les plus importantes à ajouter.
</p>
<p>
  <b>Exemple</b> : Une école de langues utilisant un site WordPress en plusieurs langues souhaite connaître la langue maternelle de ses visiteurs et quelle langue étrangère les intéresse le plus. Le but est de potentiellement lancer des publicités en ligne via Facebook Ads ciblant des démographies avec des attributs similaires.
</p>
<p>
  À l'étape suivante, nous devrons définir toutes les données pertinentes pour différents types de pages (page d'accueil, pages de cours, à propos, contact et actualités). Pour simplifier, concentrons-nous sur les pages les plus intéressantes et concentrons-nous sur la page d'accueil et les pages de cours.
</p>
<ImgContainerFixed width="400px">
  <ImgScreenshot
    src="data-layer/data-layer-example-language-school.png"
    alt="Exemple de conception de couche de données pour le site web d'une école de langues"
    caption="Exemple de conception de couche de données pour la page d'accueil et les pages de cours d'une école de langues"
  />
</ImgContainerFixed>
<p>
  <b>Exemple</b> : Couche de données Google Tag Manager basée sur des tableaux pour une école de langues
</p>
<SnippetJS caption="Extrait de code pour initier une couche de données GTM pour l'exemple d'une école de langues. Notez que les nombres sont déclarés comme des chaînes.">
  {`
window.dataLayer = window.dataLayer || [{
  "language": "de", //Langue de l'interface utilisateur
  "sessionDuration": "182", //Durée de la session en sec
  "languageIntent": "es", //Langue du cours le plus visité
  "pageType": "page de cours",
  "courseName": "Espagnol A1 - Débutant",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Durée en semaines
}];
`}
</SnippetJS>
<p>
  <b>Exemple</b> : Couche de données basée sur des objets pour Adobe Launch
</p>
<SnippetJS caption="Initialisation d'une couche de données pour Adobe Launch ou Adobe DTM.">
  {`
window.digitalData = window.digitalData || {
  "language": "de", //Langue de l'interface utilisateur
  "sessionDuration": 182, //Durée de la session en sec
  "languageIntent": "es", //Langue du cours le plus visité
  "pageType": "page de cours",
  "courseName": "Espagnol A1 - Débutant",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Durée en semaines
};
`}
</SnippetJS>
<H as="h3">2. Implémentation</H>
<p>
  Les couches de données doivent être implémentées sur chaque sous-page d'un site web. Les exemples de code ci-dessus montrent cependant l'état final <b>calculé</b>.
</p>
<p>
  Lors de l'implémentation, les points de données doivent d'abord être sourcés pour être calculés dans leur état final, de sorte que la source réelle sera légèrement différente.
</p>
<p>Pour donner un exemple réaliste, je suppose ce qui suit :</p>
<ul>
  <li>
    La durée de la session et l'intérêt pour la langue sont collectés via un JavaScript personnalisé et stockés dans le stockage local du navigateur.
  </li>
  <li>
    La langue, le type de page et les données du cours peuvent être récupérés de la base de données via la réponse du serveur et mis à disposition sur les modèles de cours et la page d'accueil.
  </li>
</ul>
<p>Le code source de la couche de données dans le backend selon les prémisses ci-dessus ressemblerait à ceci :</p>
<SnippetJS caption="Exemple de code source pour une couche de données avant calcul.">
  {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseSprache": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
</SnippetJS>
<H as="h4">Suivi des événements avec data layer push</H>
<p>Pour envoyer des événements à une couche de données GTM, vous pouvez utiliser sa méthode push et littéralement y pousser des événements.</p>
<SnippetJS caption="Exemple de data layer push pour le suivi des événements">
  {`
window.dataLayer.push({
  "event": "réservation de cours", 
  "startWeek": "24"
});
`}
</SnippetJS>
<p>
  Le mot-clé <b>event</b> est un mot-clé spécial et peut être adressé comme un <b>événement personnalisé</b> à partir du conteneur GTM.
</p>
<p>
  Le système de gestion des balises observe la couche de données et exécute une balise dès qu'un événement personnalisé prédéfini lui est envoyé.
</p>
<p>Après qu'un événement est ajouté, le TMS peut par exemple envoyer un événement à Google Analytics.</p>
<p>
  Toutes les données pertinentes pour fournir le contexte (nom, langue, niveau de langue, durée du cours) sont disponibles et peuvent être envoyées avec l'événement, par exemple, la semaine de début du cours.
</p>
<p>
  Dans une couche de données basée sur des objets, le même événement serait directement envoyé à Adobe Analytics via leur propre bibliothèque de suivi des événements.
</p>
<p>Pour Adobe Launch, le code d'exemple serait le suivant :</p>
<SnippetJS caption="Exemple Adobe Launch pour le suivi des événements. Notez que les données sont envoyées directement à Adobe Analytics sans passer par la couche de données d'abord">
  {`
_satellite.setVar("startWeek", 24);
_satellite.track("réservation de cours");
`}
</SnippetJS>
<H as="h4">Positionnement du code dans le code source</H>
<p>
  Le code de la couche de données doit être ajouté dans le <code>{`<head>`}</code> de la page avant le système de gestion des balises.
</p>
<p>
  En raison de cet ordre, vous vous assurez qu'il est déjà calculé lorsque le système de gestion des balises veut y accéder.
</p>
<p>
  <b>Exemple</b> : Positionnement dans le code source
</p>
<ImgScreenshot
  src="data-layer/positionierung-data-layer-gtm.png"
  alt="positionnement de la couche de données avant la balise TMS dans le code source"
  caption="La couche de données doit être positionnée dans la section <head> avant le système de gestion des balises, pour garantir que ses données soient déjà chargées lorsque le TMS veut y accéder."
/>
<H as="h3">3. Débogage</H>
<p>
  Les procédures les plus courantes pour déboguer une couche de données consistent à simuler des chargements de page ou des événements pour vérifier que tous les points de données se remplissent avec les données correctes.
</p>
<p>
  Comme elle est accessible globalement dans la console du navigateur, vous pouvez simplement imprimer toutes les valeurs dans la console (en supposant que des conventions de nommage standard sont appliquées) :
</p>
<p>
  <b>Sites web avec GTM</b>
</p>
<p>
  <code>Object.assign(...dataLayer)</code>
</p>
<p>
  <b>Sites web avec Adobe Launch ou DTM</b>
</p>
<p>
  <code>digitalData</code>
</p>
<p>
  <b>Tealium</b>
</p>
<p>
  <code>utag.data</code> ou <code>utag_data</code>
</p>
<p>
  Google Tag Manager est même livré avec son propre <strong>mode Débogueur</strong>. Vous pouvez l'activer depuis l'interface GTM en cliquant sur <b>aperçu</b> en haut à droite.
</p>
<ImgScreenshot
  src="data-layer/gtm-activate-debugger-mode.png"
  alt="Capture d'écran de Google Tag Manager montrant le bouton pour activer le mode débogage"
  caption={`Vous pouvez activer le mode débogage de GTM en cliquant sur le bouton d'aperçu. Si vous visitez ensuite votre site avec le conteneur GTM installé, la fenêtre de débogage apparaîtra en bas du navigateur.`}
/>{" "}
<ImgScreenshot
  src="data-layer/gtm-debugger-mode.png"
  alt="capture d'écran de la fenêtre du débogueur GTM"
  caption="Vous pouvez inspecter toutes les variables de la couche de données et voir leurs valeurs actuelles pendant que vous interagissez avec le site web. De plus, vous pouvez surveiller la séquence des événements qui entrent. Cliquez sur chaque événement pour voir les changements causés par l'événement."
/>
<p>
  Si vous n'avez pas accès au conteneur de gestion des balises mais souhaitez quand même le déboguer, vous pouvez utiliser une <b>extension Chrome</b>.
</p>
<H as="h4">Extensions Chrome pour la couche de données</H>
<p>
  Il existe une variété d'extensions potentielles pour le débogage. Je préfère celles qui prennent en charge la plupart des fournisseurs, afin de ne pas avoir à changer d'extension lors du débogage d'un autre site.
</p>
<p>Les extensions Chrome suivantes sont actuellement mes préférées pour le débogage :</p>
<ul>
  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
    >
      <b>Trackie</b>
    </a>{" "}
    - Extension basée sur{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
    >
      Data Slayer
    </a>{" "}
    et open source. Elle offre de bonnes performances et supporte GTM, DTM, Tealium{" "}
    <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
      et bien d'autres
    </a>
    .
  </li>

  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
    >
      <b>Omnibug</b>
    </a>{" "}
    - Un autre tout-en-un avec support pour Adobe Analytics (DTM & Launch), ainsi que Matomo, GTM, Tealium{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
    >
      et plus
    </a>
    .
  </li>
</ul>
<H as="h4">Extensions Chrome pour déboguer Google Analytics et GTM</H>
<ul>
  <li>
    <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
      <b>GTM/GA Debug</b>
    </a>{" "}
    - après activation de l'extension, un nouvel onglet sera disponible dans les outils de développement Chrome une fois ouverts (F12 sur Windows et CTRL+SHIFT+i sur Mac). Toutes les données pertinentes sont affichées dans une interface graphique et sont mises à jour pendant que vous naviguez sur un site web.
  </li>
  <li>
    <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
      <b>Adswerve - dataLayer Inspector+</b>
    </a>{" "}
    - enregistre tous les points de données pertinents dans la console du navigateur. Activez "conserver le journal" dans les paramètres de la console pour conserver les journaux lors de la navigation sur la page.
  </li>
  <li>
    <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
      <b>Data Slayer</b>
    </a>{" "}
    - définitivement l'extension avec le nom et le logo les plus cools et mon ancien favori. Elle est open source avec une mise en page simple et fonctionne également avec Adobe DTM. <br />
    <u>
      Décochez “utiliser la mise en page à trois colonnes là où c'est disponible”, “afficher les tags GA Classic” et “afficher les tags Floodlight” dans les paramètres de l'extension,
    </u>{" "}
    sinon les journaux deviennent un peu désordonnés.
  </li>
</ul>
<H as="h4">Extensions Chrome pour déboguer Adobe Analytics, Launch et DTM</H>
<ul>
  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
    >
      <b>Launch & DTM Switch</b>
    </a>{" "}
    - vous permet de charger la bibliothèque de mise en scène ou de production du système de gestion des balises et peut activer le mode débogage.
  </li>

  <li>
    <a
      rel="noopener"
      target="_blank"
      href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
    >
      <b>Debugger for Adobe Analytics</b>
    </a>{" "}
    - active le mode débogage. Alternativement, vous pouvez également taper <code>_satellite.setDebug(true)</code> dans la console.
  </li>

  <li>
  <a
    rel="noopener"
    target="_blank"
    href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
  >
    <b>Adobe Experience Cloud Debugger</b>
  </a>{" "}
  - méta extension pour déboguer tous les produits Adobe.
</li>

<li>
  <a
    rel="noopener"
    target="_blank"
    href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
  >
    <b>Adobe Experience Platform Debugger</b>
  </a>{" "}
  - successeur du débogueur Experience Cloud qui offre une meilleure vue d'ensemble (actuellement encore en version bêta).{" "}
</li>

<li>
  <a
    rel="noopener"
    target="_blank"
    href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
  >
    <b>Tealium Data Layer Debugger</b>
  </a>{" "}
  - tableaux simples de toutes les valeurs actuelles.
</li>
</ul>
<H as="h2">Couche de données pour le commerce électronique</H>
<p>
  Les couches de données pour le commerce électronique sont plus étendues et leur structure est plus complexe. Elles doivent contenir plus de données et gérer plus d'événements.
</p>
<p>C'est pourquoi la planification et l'implémentation d'un site de commerce électronique prennent considérablement plus de temps.</p>
<p>
  Le rapport de commerce électronique dans Google Analytics, par exemple, ne montre aucune donnée si l'implémentation ne suit pas leur{" "}
  <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=fr">
    documentation de la couche de données pour le commerce électronique
  </a>
  .
</p>
<p>
  Les grands magasins de commerce électronique nécessitent cependant des configurations de suivi encore plus avancées. Ils implémentent une{" "}
  <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
    couche de données pour le commerce électronique amélioré
  </a>
  , ce qui permet encore plus de fonctionnalités dans Google Analytics.
</p>
<p>
  Vous devez suivre strictement les lignes directrices d'implémentation pour Google Analytics pour que les rapports de commerce électronique fonctionnent. Cela concerne la structure de la couche de données et également les noms de variables.
</p>
<p>
  Si vous choisissez une autre plateforme d'analyse pour le commerce électronique, vous êtes libre de planifier la structure comme vous le souhaitez.
</p>
<H as="h2">Quand une couche de données n'est-elle pas nécessaire ?</H>
<p>
  Comme pour tout, il existe aussi des cas où l'effort supplémentaire d'implémentation d'une couche de données n'est pas justifié.
</p>
<p>
  Dans les exemples ci-dessus, nous avons examiné des cas où nous avons extrait des données de différentes sources de données (Frontend, Backend, API) et résolu des problèmes qui se posent lorsqu'on travaille avec une variété de sources de données.
</p>
<p>
  De nombreux sites web (appelés sites vitrine) n'ont cependant même pas de fonctionnalité de connexion ni de base de données.
</p>
<p>
  Un autre facteur important est la fréquence des changements apportés au site web. De nombreux sites révisent rarement leur contenu ou ajoutent régulièrement des fonctionnalités. Je vois même des entreprises gérer des sites vitrine simples avec environ 50 sous-pages et un formulaire de contact comme la conversion la plus difficile.
</p>
<p>
  Comme ces sites ne nécessitent probablement que des données provenant du frontend pour faire leur analyse de données, ils pourraient se contenter d'une configuration d'analyse simple sans couche de données. Cela ne rendrait pas la collecte de données beaucoup plus robuste ou plus sécurisée, ses avantages sont donc atténués. Dans de telles circonstances, les avantages ne justifient pas l'effort supplémentaire d'implémentation.
</p>
<p>
  Les exemples typiques de moments où une couche de données n'est pas nécessaire sont les sites vitrine ou les sites de contenu avec un nombre limité ou presque aucune conversion difficile. En général, les propriétaires de tels sites souhaitent simplement catégoriser l'engagement des utilisateurs par leurs sections de contenu ou certaines classifications internes à l'entreprise.
</p>
<p>
  De telles exigences peuvent être réalisées avec un peu de JavaScript avancé et un système bien pensé pour structurer le contenu.
</p>
<p>
  Dès que la collecte de données à partir du frontend se casse régulièrement et définitivement lorsque une base de données doit être impliquée, une couche de données est recommandée.
</p>
<p>
  Les solutions alternatives ne sont souvent que des solutions temporaires satisfaisantes, en raison des ambitions croissantes en matière d'analyse et de la collecte de données qui se casse régulièrement. De plus, toutes les solutions personnalisées sont généralement difficiles à transmettre à une autre agence.
</p>
<p>
  Une couche de données a de bonnes chances de résister à l'épreuve du temps car elle est déjà un concept établi dans le secteur de l'analytique web, donc les agences web ont de plus en plus d'expérience dans l'implémentation et la maintenance de celle-ci.
</p>
<H as="h2">Conclusion</H>
<p>
  Une couche de données est le standard d'or pour les configurations analytiques professionnelles. Elle améliore la qualité des données et, par conséquent, améliore l'analyse des données dans son ensemble, tout en répondant aux exigences de sécurité.
</p>
<p>
  Pour les propriétaires de sites web ambitieux qui souhaitent commencer une analyse de données sérieuse, c'est la solution la plus simple et la plus robuste.
</p>
<p>
  Si vous avez le choix, implémentez une structure basée sur des tableaux, car vous avez moins de dépendances et vous pouvez l'utiliser sur tous types de sites web.
</p>
<p>
  Les sites de contenu, cependant, sont si limités en termes de fonctionnalité et apportent généralement un nombre limité de conversions importantes, qu'une couche de données peut potentiellement être négligée. Cela est particulièrement vrai si toutes les données nécessaires sont disponibles sur les pages ou pourraient être rendues disponibles par des détours.
</p>
<p>
  Si vous voulez en implémenter une vous-même, le faire avec un site WordPress est probablement le plus facile. Cependant, toute exigence avancée ne vaut probablement pas le temps et le risque de résultats insatisfaisants.
  <br />
  Par conséquent, l'implémentation avec l'aide d'un consultant en analytics est généralement la voie à suivre, car elle permet de gagner du temps et d'éviter des risques inutiles.
</p>
<p>
  Je vous recommande d'installer l'une des extensions Chrome mentionnées pour inspecter les couches de données de certains grands sites web. C'est généralement une grande source d'inspiration et fournit des indicateurs clés de performance intéressants à potentiellement intégrer dans vos propres configurations analytiques 😉.
</p>
<H as="h2">Documentation des couches de données des différents fournisseurs de TMS</H>
<ul>
  <li>
    <b>Google Tag Manager</b>:{" "}
    <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
      Initialisation et modification des données
    </a>
  </li>

  <li>
    <b>Adobe Launch</b>:{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://docs.adobe.com/content/help/en/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
    >
      Initialisation
    </a>
  </li>

  <li>
    <b>Tealium iQ</b>:{" "}
    <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
      Initialisation
    </a>{" "}
    et{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
    >
      modification des données
    </a>
  </li>

  <li>
    <b>Matomo</b>:{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
    >
      Initialisation
    </a>{" "}
    et{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
    >
      modification des données
    </a>
  </li>

  <li>
    <b>Piwik Pro</b>:{" "}
    <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
      Initialisation
    </a>{" "}
    et{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
    >
      modification des données
    </a>
  </li>
</ul>
<H as="h2">FAQ</H>
<FaqComponent data={{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Qu'est-ce qu'un exemple de couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Un exemple de couche de données est fourni dans l'article. Un objet JavaScript stocke les données d'un site web, d'une base de données ou d'une source externe de manière centrale, flexible et facilement accessible. Un exemple de code pour initier une couche de données pour Google Tag Manager est : window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"page de catégorie\", \"pageName\": \"aperçu des sneakers\", \"language\": \"fr-FR\",}];"
      }
    },
    {
      "@type": "Question",
      "name": "Quelles sont les variables de la couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Les variables de la couche de données sont des paires clé-valeur au sein de la couche de données qui stockent des informations spécifiques. Ces variables peuvent inclure les caractéristiques de la page, les données de comportement des utilisateurs, et plus encore, servant de référentiel central de données pour l'analyse et le suivi."
      }
    },
    {
      "@type": "Question",
      "name": "Pourquoi utiliser une couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Une couche de données est essentielle pour une collecte de données robuste, flexible et sécurisée. Elle centralise les données provenant de diverses sources, les rendant facilement accessibles et cohérentes sur différentes pages web et interactions utilisateur. Cette approche améliore la qualité et la fiabilité des données, ce qui est crucial pour la prise de décisions basées sur les données."
      }
    },
    {
      "@type": "Question",
      "name": "Ai-je besoin d'une couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Bien qu'elle ne soit pas toujours nécessaire, une couche de données est généralement conseillée pour ceux qui prennent leurs ambitions d'analyse de données au sérieux. Elle offre une qualité de données, une fiabilité et des économies de temps à long terme qui justifient les efforts d'implémentation plus élevés."
      }
    },
    {
      "@type": "Question",
      "name": "Quels sont les avantages d'une couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Les avantages d'une couche de données incluent : La disponibilité des données qu'elles soient visibles sur la page ou non. Une collecte de données robuste. La réduction de la perte de données pour les requêtes de données asynchrones. Une collecte de données sécurisée à partir de multiples sources."
      }
    },
    {
      "@type": "Question",
      "name": "Tous les sites web ont-ils une couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Tous les sites web n'ont pas une couche de données. Son implémentation dépend de la complexité du site et de la profondeur de l'analyse des données requise. Les sites simples peuvent ne pas avoir de couche de données, tandis que les sites plus complexes, en particulier ceux axés sur la prise de décisions basées sur les données, en auront probablement une."
      }
    },
    {
      "@type": "Question",
      "name": "Comment accéder à la couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "La couche de données est accessible globalement dans la console du navigateur. Pour les sites avec Google Tag Manager, vous pouvez y accéder en utilisant dataLayer ou Object.assign(...dataLayer). Pour Adobe Launch ou DTM, vous pouvez y accéder en utilisant digitalData."
      }
    },
    {
      "@type": "Question",
      "name": "Comment ajouter des données à la couche de données ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pour ajouter des données à la couche de données, vous utilisez la méthode dataLayer.push(). Par exemple : window.dataLayer.push({ \"event\": \"réservation de cours\", \"startWeek\": \"24\" }); Cette méthode est utilisée pour ajouter de nouvelles données ou des modifications à la couche de données. La clé event peut être utilisée pour déclencher une autre exécution de balise dans le système de gestion des balises."
      }
    }
  ]
}
} />
<H as="h2">Ressources supplémentaires</H>
<ul>
  <li>
    Simo Ahava à propos de la{" "}
    <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
      couche de données dans GTM
    </a>{" "}
    et{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
    >
      comment gérer les données dans celle-ci.
    </a>
  </li>
  <li>Lisez mon <Link to="/fr/configuration-de-google-tag-manager">tutoriel sur Google Tag Manager</Link> et apprenez à le configurer.</li>
  <li>
    La présentation de Kevin Haag à Measurecamp Berlin 2019 sur la{" "}
    <a
      rel="noopener"
      target="_blank"
      href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
    >
      couche de données pilotée par les événements dans Adobe Analytics
    </a>
  </li>
</ul>
</MainContent>
</React.Fragment>

</Layout>
);

export default DataLayer;
